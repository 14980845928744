@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins&display=swap");

body, html, #root, .App {
  height: 100%;
  width: 100%
}

body, html {
  margin: 0; padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light { background-color: #F4F5FA; color: #000000 }
body.dark { background-color: #333333; color: #FFFFFF }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blink {
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}

/* .customScroll {
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin
} */


.customScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scroller */

.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Track */
.hideScroll::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.hideScroll::-webkit-scrollbar-thumb {
  display: none;
}

/* Handle on hover */
.hideScroll::-webkit-scrollbar-thumb:hover {
  display: none;
}

/* hide scroller end */

.rdrDateDisplayWrapper, .rdrInputRanges {
  display: none;
}

.rdrMonthAndYearWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 40px;
  /* border-top: 1px solid #FF5E0E; */
  border-bottom: 1px solid #FF5E0E;
}
.rdrDefinedRangesWrapper {
  border-right: 1px solid #FF5E0E;
}

.rdrStaticRanges button, .rdrStaticRangeLabel {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}


.kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #B4B4B4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 12px;
  white-space: nowrap;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rdrDefinedRangesWrapper {
    width: 140px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .rdrDefinedRangesWrapper {
    width: 140px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .rdrDefinedRangesWrapper {
    width: 226px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .rdrDefinedRangesWrapper {
    width: 226px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .rdrDefinedRangesWrapper {
    width: 226px;
  }
}

.bounceLeft {
  animation: myfirst 1s infinite;  
}

@keyframes myfirst {
  0%   {left: -40px;}
  50%  {left: -80px;}
  100% {left: -40px;}
}


/* Wave animation */



.path-0{
  animation:pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0{
  0%{
    d: path("M 0,700 C 0,700 0,233 0,233 C 106.77511961722487,243.88995215311004 213.55023923444975,254.7799043062201 309,243 C 404.44976076555025,231.2200956937799 488.57416267942585,196.77033492822966 565,195 C 641.4258373205741,193.22966507177034 710.153110047847,224.13875598086122 816,248 C 921.846889952153,271.8612440191388 1064.8133971291866,288.67464114832535 1175,285 C 1285.1866028708134,281.32535885167465 1362.5933014354068,257.1626794258373 1440,233 C 1440,233 1440,700 1440,700 Z");
  }
  25%{
    d: path("M 0,700 C 0,700 0,233 0,233 C 103.77033492822966,251.09569377990428 207.54066985645932,269.19138755980856 296,291 C 384.4593301435407,312.80861244019144 457.6076555023924,338.3301435406699 546,306 C 634.3923444976076,273.6698564593301 738.0287081339712,183.48803827751195 855,171 C 971.9712918660288,158.51196172248805 1102.2775119617224,223.71770334928232 1202,247 C 1301.7224880382776,270.2822966507177 1370.8612440191387,251.64114832535884 1440,233 C 1440,233 1440,700 1440,700 Z");
  }
  50%{
    d: path("M 0,700 C 0,700 0,233 0,233 C 90.43062200956936,205.10526315789474 180.86124401913872,177.21052631578945 280,199 C 379.1387559808613,220.78947368421055 486.9856459330143,292.2631578947369 589,309 C 691.0143540669857,325.7368421052631 787.1961722488039,287.7368421052631 885,249 C 982.8038277511961,210.26315789473685 1082.2296650717703,170.78947368421052 1175,168 C 1267.7703349282297,165.21052631578948 1353.8851674641148,199.10526315789474 1440,233 C 1440,233 1440,700 1440,700 Z");
  }
  75%{
    d: path("M 0,700 C 0,700 0,233 0,233 C 116.1244019138756,196.66507177033492 232.2488038277512,160.33014354066987 322,156 C 411.7511961722488,151.66985645933013 475.12918660287073,179.3444976076555 569,223 C 662.8708133971293,266.6555023923445 787.2344497607656,326.2918660287081 892,319 C 996.7655502392344,311.7081339712919 1081.933014354067,237.48803827751198 1170,212 C 1258.066985645933,186.51196172248802 1349.0334928229665,209.755980861244 1440,233 C 1440,233 1440,700 1440,700 Z");
  }
  100%{
    d: path("M 0,700 C 0,700 0,233 0,233 C 106.77511961722487,243.88995215311004 213.55023923444975,254.7799043062201 309,243 C 404.44976076555025,231.2200956937799 488.57416267942585,196.77033492822966 565,195 C 641.4258373205741,193.22966507177034 710.153110047847,224.13875598086122 816,248 C 921.846889952153,271.8612440191388 1064.8133971291866,288.67464114832535 1175,285 C 1285.1866028708134,281.32535885167465 1362.5933014354068,257.1626794258373 1440,233 C 1440,233 1440,700 1440,700 Z");
  }
}

/* .path-1{
  animation:pathAnim-1 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1{
  0%{
    d: path("M 0,600 C 0,600 0,400 0,400 C 78.96650717703349,429.03349282296654 157.93301435406698,458.066985645933 245,450 C 332.066985645933,441.933014354067 427.23444976076553,396.76555023923447 542,396 C 656.7655502392345,395.23444976076553 791.1291866028707,438.87081339712915 885,432 C 978.8708133971293,425.12918660287085 1032.2488038277513,367.75119617224885 1118,354 C 1203.7511961722487,340.24880382775115 1321.8755980861242,370.12440191387554 1440,400 C 1440,400 1440,600 1440,600 Z");
  }
  25%{
    d: path("M 0,600 C 0,600 0,400 0,400 C 106.5263157894737,432.9952153110048 213.0526315789474,465.99043062200957 310,466 C 406.9473684210526,466.00956937799043 494.31578947368416,433.0334928229665 575,430 C 655.6842105263158,426.9665071770335 729.684210526316,453.8755980861244 837,461 C 944.315789473684,468.1244019138756 1084.9473684210525,455.46411483253587 1191,442 C 1297.0526315789475,428.53588516746413 1368.5263157894738,414.26794258373207 1440,400 C 1440,400 1440,600 1440,600 Z");
  }
  50%{
    d: path("M 0,600 C 0,600 0,400 0,400 C 80.27751196172247,426.4019138755981 160.55502392344494,452.80382775119614 252,456 C 343.44497607655506,459.19617224880386 446.0574162679426,439.18660287081343 552,416 C 657.9425837320574,392.81339712918657 767.2153110047848,366.44976076555025 864,365 C 960.7846889952152,363.55023923444975 1045.0813397129186,387.0143540669857 1139,397 C 1232.9186602870814,406.9856459330143 1336.4593301435407,403.49282296650716 1440,400 C 1440,400 1440,600 1440,600 Z");
  }
  75%{
    d: path("M 0,600 C 0,600 0,400 0,400 C 98.43062200956936,355.8755980861244 196.86124401913872,311.7511961722488 294,326 C 391.1387559808613,340.2488038277512 486.98564593301444,412.8708133971292 578,411 C 669.0143540669856,409.1291866028708 755.1961722488038,332.7655502392344 853,335 C 950.8038277511962,337.2344497607656 1060.2296650717703,418.066985645933 1160,442 C 1259.7703349282297,465.933014354067 1349.8851674641148,432.96650717703346 1440,400 C 1440,400 1440,600 1440,600 Z");
  }
  100%{
    d: path("M 0,600 C 0,600 0,400 0,400 C 78.96650717703349,429.03349282296654 157.93301435406698,458.066985645933 245,450 C 332.066985645933,441.933014354067 427.23444976076553,396.76555023923447 542,396 C 656.7655502392345,395.23444976076553 791.1291866028707,438.87081339712915 885,432 C 978.8708133971293,425.12918660287085 1032.2488038277513,367.75119617224885 1118,354 C 1203.7511961722487,340.24880382775115 1321.8755980861242,370.12440191387554 1440,400 C 1440,400 1440,600 1440,600 Z");
  }
} */





.uploadHostCSVFile::-webkit-file-upload-button {
  visibility: hidden;
}
.uploadHostCSVFile::before {
  content: 'Select';
  color: #ffffff;
  width: 100px;
  /* margin-left: 25px; */
  display: inline-block;
  padding: 8px 25px;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
}

.auditTrailCalendar > .rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper > .rdrStaticRanges > button:nth-child(1),
.auditTrailCalendar > .rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper > .rdrStaticRanges > button:nth-child(3),
.auditTrailCalendar > .rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper > .rdrStaticRanges > button:nth-child(5) {
  display: none
}

div.rdrDefinedRangesWrapper > div.rdrStaticRanges > button:nth-child(1),
div.rdrDefinedRangesWrapper > div.rdrStaticRanges > button:nth-child(3),
div.rdrDefinedRangesWrapper > div.rdrStaticRanges > button:nth-child(5) {
  display: none
}

@media only screen and (max-width: 600px){
  .rdrDefinedRangesWrapper  {
    display: none;
  }
}


